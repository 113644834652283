import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/#404"
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/ServiceView.vue')
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/GuideView.vue')
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/Board/BoardListView.vue')
  },
  {
    path: '/notice/:id',
    name: 'notice:detail',
    component: () => import('@/views/Board/BoardDetailView.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/Board/BoardListView.vue')
  },
  {
    path: '/faq/:id',
    name: 'faq:detail',
    component: () => import('@/views/Board/BoardDetailView.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/PrivacyView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('@/views/InquiryView.vue')
  },
  {
    path: '/inquiry/partner',
    name: 'inquiry-partner',
    component: () => import('@/views/InquiryView.vue')
  },
  {
    path: '/task/new',
    name: 'task:new',
    component: () => import('@/views/Task/TaskNewView.vue')
  },
  {
    path: '/task/my',
    name: 'task:my-list',
    component: () => import('@/views/Task/TaskListView.vue')
  },
  {
    path: '/task/my/:id',
    name: 'task:my-detail',
    component: () => import('@/views/Task/TaskDetailView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

export default router
