<script>
import { defineComponent } from 'vue';
import LayoutHeader from "@/components/LayoutHeader.vue";
import LayoutFooter from "@/components/LayoutFooter.vue";
import ImageModal from "@/components/ImageModal.vue";

export default defineComponent({
  components: {
    // ImageModal,
    LayoutHeader, LayoutFooter
  },
  data() {
    return {
      workImages: null,
      selectWorkImageModal: null,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    }
  },
  methods: {
    check404() {
      if (location.href.includes('#404')) {
        alert('페이지를 찾을 수 없습니다.');
        this.$router.push('/')
      }
    },
    async requestWorkImages() {
      try {
        const response = await this.$server.boardDetail('document', 2)
        this.workImages = response.data?.board_images?.map(row => row.url)
      } catch (e) {
        console.warn(e)
      }
    },
  },
  mounted() {
    this.check404()
    this.requestWorkImages()
  },
  updated() {
    this.check404()
  }
});
</script>

<template>
<!--  <ImageModal v-if="selectWorkImageModal" :url="selectWorkImageModal" @modal:close="selectWorkImageModal = null" />-->
  <div style="background: #fff">
    <LayoutHeader />
    <div class="user" v-if="user">
      <span>{{ user.name }}</span>님 환영합니다.
      <button type="button" class="button small-button" @click="$store.commit('setToken', null)">로그아웃</button>
    </div>
    <div class="user" v-else>
      손님 환영합니다.
      <router-link to="/login" class="button small-button">로그인</router-link>
    </div>
    <div class="quick-menu">
      <router-link to="/task/new" class="main-button button-task-new">작업 요청하기</router-link>
      <router-link to="/task/my" class="main-button button-task-confirm">진행상황 확인</router-link>
    </div>
    <router-link to="/inquiry" class="round-button">문의하기</router-link>
    <div class="banner">
      <img src="@/assets/banner_campaign.png" />
    </div>
    <div class="banner">
      <router-link to="/inquiry/partner"><img src="@/assets/banner_partnership.png" alt="파트너 제휴상담 바로가기" /></router-link>
    </div>
    <div class="review">
      <h3>예초맨 작업 후기 사진</h3>
      <div class="spinner" v-if="workImages === null">
        <div class="spinner-sector spinner-sector-gray"></div>
      </div>
      <div class="scroll-box" v-else>
        <img v-for="url in workImages" :key="url" :src="url" @click.prevent="selectWorkImageModal = url" />
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<style scoped>
.user {
  text-align: start;
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.user > .button {
  float: right;
  margin-top: 0.1rem;
}

.user > span {
  color: #1AC7B1;
}

.quick-menu {
  display: flex;
  margin: 0 1rem;
  clear: both;
}

.quick-menu > .main-button {
  display: block;
  background-color: #EFF3FF;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  flex-grow: 1;
  height: 10rem;
  line-height: 15rem;
  color: #3a3428;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
}

.quick-menu > .main-button:first-child {
  margin-right: 1rem;
}

.quick-menu > .button-task-new {
  background-image: url('@/assets/ic_main_confirm.svg');
  background-repeat: no-repeat;
  background-position: center 35%;
}

.quick-menu > .button-task-confirm {
  background-image: url('@/assets/ic_main_new.svg');
  background-repeat: no-repeat;
  background-position: center 35%;
}

.banner {
  margin: 1rem;
}

.banner img {
  max-width: 100%;
  width: 100%;
}

.review {
  margin-bottom: 1rem;
}

.review > h3 {
  text-align: start;
  font-size: 1rem;
  margin: 0 1rem 0.5rem 1rem;
}

.review .scroll-box {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 1rem;
  height: 8rem;
}

.review .scroll-box::-webkit-scrollbar {
  display: none;
}

.review .scroll-box > img {
  height: 100%;
  margin-right: 0.4rem;
}
.review .scroll-box > img:last-child {
  margin: 0
}
</style>