import {App} from "vue";
import store from './store'

class ServerAPI {
    opts = {
        API_URL: '/api/'
    };
    app:any = null

    async taskWrite(params: any) {
        const endpoint = `task`
        const response = await this.request(endpoint, {
            method: 'POST',
            body: JSON.stringify(params)
        })

        return await response.json()
    }

    async taskList(params: any) {
        let endpoint = `task`

        if (params) {
            const query = new URLSearchParams(params).toString()
            endpoint = `${endpoint}?${query}`
        }

        const response = await this.request(endpoint, {
            method: 'GET',
        });

        return await response.json()
    }

    async taskDetail(seq: number, secret: string|null) {
        const endpoint = `task/${seq}?secret=${secret ?? ''}`
        const response = await this.request(endpoint, {
            method: 'GET',
        });

        if (response.status !== 200) {
            throw await response.json()
        }

        return await response.json()
    }

    async taskOrder(seq: number) {
        const endpoint = `task/${seq}`
        const response = await this.request(endpoint, {
            method: 'PATCH',
            body: JSON.stringify({
                "status": 2
            })
        })

        return await response.json()
    }

    async taskCancel(seq: number) {
        const endpoint = `task/${seq}`
        const response = await this.request(endpoint, {
            method: 'PATCH',
            body: JSON.stringify({
                "status": 5,
                "cancel_reason": "@todo 취소 사유 모달로 받을것"
            })
        })

        return await response.json()
    }

    async boardList(id: string, params: any = null) {
        let endpoint = `board/${id}`

        if (params) {
            const query = new URLSearchParams(params).toString()
            endpoint = `${endpoint}?${query}`
        }

        const response = await this.request(endpoint, {
            method: 'GET',
        });

        return await response.json()
    }

    async boardDetail(id: string, seq: number) {
        const endpoint = `board/${id}/${seq}`
        const response = await this.request(endpoint, {
            method: 'GET',
        });

        return await response.json()
    }

    async inquiryWrite(params: any) {
        const endpoint = `inquiry`
        const response = await this.request(endpoint, {
            method: 'POST',
            body: JSON.stringify(params)
        });

        return await response.json()
    }

    async authAuthorize(params: any) {
        const endpoint = `auth/authorize`
        const response = await this.request(endpoint, {
            method: 'POST',
            body: JSON.stringify(params)
        });

        return await response.json()
    }

    async authToken(params: any) {
        const endpoint = `auth/token`
        const response = await this.request(endpoint, {
            method: 'POST',
            body: JSON.stringify(params)
        });

        return await response.json()
    }

    async imageUpload(file: any) {
        const endpoint = `system/image-upload`

        const formData = new FormData();
        formData.append("file[]", file);

        const response = await this.request(endpoint, {
            method: 'POST',
            body: formData
        }, {});

        return await response.json()
    }

    install (app:App, options:any = {}) {
        this.opts = {...this.opts, ...options}
        this.app = app

        app.config.globalProperties.$server = this
    }

    protected async request(resource: string, options: any, headers:any = { "Content-Type": "application/json" }) {
        if ((resource[0] ?? '') === '/')
            resource = resource.substring(1)

        resource = this.opts.API_URL + resource

        // timeout 설정
        const { timeout = 8000 } = options
        const controller = new AbortController()
        const id = setTimeout(() => controller.abort(), timeout)

        // 인증 헤더
        const accessToken = store.state.accessToken
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }

        // 서버로 요청
        const response = await fetch(resource, {
            cache: 'no-cache',
            redirect: 'follow',
            ...options,
            headers: headers,
            signal: controller.signal
        })
        clearTimeout(id)

        return response
    }
}

export default new ServerAPI()