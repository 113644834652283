import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './services/store'
import server from './services/server'
import format from './services/format'

console.log(process.env);

createApp(App)
    .use(store)
    .use(router)
    .use(server, { API_URL: process.env.VUE_APP_API_URL })
    .use(format)
    .mount('#app')
