
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    menuOn() {
      if (! this.showMenu) {
        this.showMenu = true;
        this.$store.commit('onModalBackground')
      }
    },
    menuOff() {
      if (this.showMenu) {
        this.showMenu = false;
        this.$store.commit('offModalBackground')
      }
    }
  }
});
