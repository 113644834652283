import {createStore} from "vuex";

export default createStore({
    state: {
        accessToken: localStorage.getItem('accessToken') ?? null,
        modalBackgroundTransaction: 0
    },
    mutations: {
        setToken(state:any, token:string) {
            state.accessToken = token
            localStorage.setItem('accessToken', token)
        },
        onModalBackground(state:any) {
            state.modalBackgroundTransaction++
        },
        offModalBackground(state:any) {
            state.modalBackgroundTransaction--
        }
    },
    getters: {
        user(state: any) {
            if (! state.accessToken)
                return null

            try {

                const payload = JSON.parse(atob(state.accessToken.split('.')[1]));
                return {
                    name: payload.nme,
                    phone: payload.phn
                }
            } catch (e) {
                console.error(e)
                return null;
            }
        }
    }
})