<template>
  <div class="layout-bottom">
    <img src="@/assets/logo_white.svg" />
    <p>
      에트윅 / 124-38-34089 / 대표자 임병우<br />
      경기도 수원시 권선구 정조로 420 2층<br />
      고객센터 ibw1341@atweek.com / <router-link to="/privacy">개인정보처리방침</router-link>
    </p>
  </div>
</template>

<style>
.layout-bottom {
  text-align: start;
  padding: 1rem;
  background: #3A3428;
  color: #fff;
}

.layout-bottom > p {
  font-size: 0.8rem;
}

.layout-bottom > p > a {
  color: #fff;
}
</style>