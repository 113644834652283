
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    isModalBackgroundOn() {
      return this.$store.state.modalBackgroundTransaction > 0
    }
  }
});
