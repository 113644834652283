import dayjs from "dayjs";
import 'dayjs/locale/ko'
import {App} from "vue";

const Format = {
    Number: (value: string|number) => {
        value = Number(value)
        if (isNaN(value)) {
            return '-'
        }

        const sign = value < 0 ? "-" : ""
        value = Math.abs(value)

        let result = "";
        const str = String(value)
        str.split("").forEach((c, i) => {
            const r = str.length - i
            if (i > 0 && (r % 3) === 0) {
                result += ","
            }
            result += c
        });

        return sign + result
    },
    Date: (value: string) => {
        return dayjs(value).format('YYYY.MM.DD')
    },
    TimeForSeconds: (value: number) => {
        const minutes = String(Math.floor(value / 60)).padStart(2, '0');
        const seconds = String(Math.floor(value % 60)).padStart(2, '0');
        return `${minutes}:${seconds}`
    },
    DateOrTime: (value: string) => {
        const today = dayjs()
        const date = dayjs(value)
        const format = today.isSame(date, 'day') ? 'HH:mm' : 'YYYY.MM.DD';
        return date.format(format)
    },
    DiscountPercent: (original:number, real:number) => {
        return 100 - Math.ceil(original / real * 100)
    }
}

export default {
    install (app:App, options:any = {}) {
        app.config.globalProperties.Format = Format
    }
}